import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { LanguageService } from "./language.service";

@Injectable()
export class AppDialogService {
  constructor(
    public dialog: MatDialog,
    public languageService: LanguageService
  ) {}

  open<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T, R> {
    config = config || {};
    if (!config.panelClass) {
      config.panelClass = this.languageService.currentLanguage.rtl
        ? "rtl-dialog"
        : "ltr-loalog";
    }
    return this.dialog.open(component, config);
  }
}
